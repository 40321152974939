import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SchotklokService {

  private TimePassed = 0;
  private TimeExpiration = 0;
  private TimerRunning = false;
  private TimerInterval;
  private TimerLastTickCount = 0;
  private TimerExpired = false;
  private TimerExpiredEmitted = false;
  @Output() timerExpired: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    this.restoreValues();
    if (this.TimerRunning) {
      this.startTimer();
    }
  }

  public resetAll() {
    this.resetTimer();
  }

  get timePassed(): number {
    return this.TimePassed;
  }

  get timeRemaining(): number {
    return this.TimerExpired ? 0 : this.TimeExpiration - this.TimePassed;
  }

  get timerRunning(): boolean {
    return this.TimerRunning;
  }

  public toggle() {
    this.TimerRunning ? this.stopTimer() : this.startTimer();
  }

  public startTimer() {
    const interval = 100;
    if (this.TimerLastTickCount === 0) {
      this.TimerLastTickCount = new Date().getTime();
    }
    this.TimerInterval = setInterval(() => {
      const now = new Date().getTime();
      this.TimePassed += (now - this.TimerLastTickCount);
      this.TimerLastTickCount = now;
      this.TimerExpired = this.TimePassed >= this.TimeExpiration;
      // TimerExpired?
      if (this.TimerExpired && !this.TimerExpiredEmitted) {
        this.timerExpired.emit(true);
        this.TimerExpiredEmitted = true;
      }
      this.storeValues();
    }, interval);
    this.TimerRunning = true;
  }

  public stopTimer() {
    clearInterval(this.TimerInterval);
    this.TimerRunning = false;
    this.TimerLastTickCount = 0;
    this.storeValues();
  }

  public resetTimer() {
    this.stopTimer();
    this.TimePassed = 0;
    this.TimerExpired = false;
    this.TimerExpiredEmitted = false;
  }

  public setTimeExpiration(seconds: number) {
    this.TimeExpiration = +seconds * 1000;
    this.storeValues();
  }

  private storeValues() {
    localStorage.setItem('schotklok', JSON.stringify({
      run: this.TimerRunning,
      tick: this.TimerLastTickCount,
      count: this.TimePassed,
      exp: this.TimeExpiration,
    }));
  }

  private restoreValues() {
    const defaults = {
      run: false,
      tick: 0,
      count: 0,
      exp: 25 * 1000,
    };
    const store = JSON.parse(localStorage.getItem('schotklok'));
    const values = {...defaults, ...store};
    if (values.count !== 'undefined') {
      this.TimerLastTickCount = values.tick;
      this.TimerRunning = values.run;
      this.TimePassed = values.count;
      this.TimeExpiration = values.exp;
    }
  }
}
