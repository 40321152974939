import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { SchotklokService } from './../../services/schotklok.service';

@Component({
  selector: 'app-schotklok',
  templateUrl: './schotklok.component.html',
  styleUrls: ['./schotklok.component.scss']
})
export class SchotklokComponent implements OnInit, OnDestroy {

  public TimerExpirationValue = 25;

  constructor(private schotklok: SchotklokService) { }

  ngOnInit() {
    this.schotklok.setTimeExpiration(this.TimerExpirationValue);
    this.schotklok.timerExpired.subscribe(response => {
      console.log('Timer Expired!2');
      let player = <HTMLAudioElement>document.getElementById('player');
      player.play();
    });
  }

  ngOnDestroy() {
    this.schotklok.timerExpired.unsubscribe();
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    switch(event.code.toLowerCase()) {
      case 'space':
        if (event.ctrlKey) {
          this.onResetTimer();
        } else {
          this.onToggleTimer();
        }
        break;
    }
  }

  get timePassed(): number {
    return this.schotklok.timePassed;
  }
  get timeRemaining(): number {
    return this.schotklok.timeRemaining;
  }
  get secondsRemaining(): number {
    return this.schotklok.timeRemaining / 1000;
  }

  get timerRunning(): boolean {
    return this.schotklok.timerRunning;
  }

  public onToggleTimer() {
    this.schotklok.toggle();
  }

  public onStartTimer() {
    this.schotklok.startTimer();
  }

  public onStopTimer() {
    this.schotklok.stopTimer();
  }

  public onResetTimer() {
    this.schotklok.resetTimer();
  }

}
